// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PublicClientApplication } from '@azure/msal-browser';
import { LogLevel } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const chrome = ua.indexOf("Chrome") > -1 && ua.indexOf("Edge/") === -1; // Ensure it's not Edge
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
const isChrome = chrome;

if (!process.env.REACT_APP_MSAL_CLIENT_ID) {
  throw new Error('REACT_APP_MSAL_CLIENT_ID is not set');
}

if (!process.env.REACT_APP_MSAL_SIGNIN_FLOW) {
  throw new Error('REACT_APP_MSAL_SIGNIN_FLOW is not set');
}

if (!process.env.REACT_APP_MSAL_AUTHORITY_TEMPLATE) {
  throw new Error('REACT_APP_MSAL_AUTHORITY_TEMPLATE is not set');
}

if (!process.env.REACT_APP_MSAL_DOMAIN) {
  throw new Error('REACT_APP_MSAL_DOMAIN is not set');
}

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: `${process.env.REACT_APP_MSAL_SIGNIN_FLOW}`,
    // editProfile: "B2C_1_ProfileEditPolicy"
  },
  authorities: {
    signUpSignIn: {
      authority: `${process.env.REACT_APP_MSAL_AUTHORITY_TEMPLATE}`
    },
    // editProfile: {
    //   authority: `${process.env.REACT_APP_MSAL_DOMAIN}/${process.env.REACT_APP_MSAL_POLICY}`
    // }
  },
  authorityDomain: `${process.env.REACT_APP_MSAL_DOMAIN}`
}

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_PUBLIC_URL || "http://localhost:3000/",
    postLogoutRedirectUri: process.env.REACT_APP_PUBLIC_URL || "http://localhost:3000/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox || isChrome
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      logLevel: LogLevel.Warning,
      // @ts-ignore
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
  scopes: [`${process.env.REACT_APP_B2C_TENANT}/api/User.All`]
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
// export const apiConfig = {
//     scopes: ['https://co2tooldevb2c.onmicrosoft.com/api/User.All'],
//     uri: 'https://co2tooldevb2c.onmicrosoft.com/co2tooldevb2capi'
// };
