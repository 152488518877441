/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: enable no-unused-vars
import React, { useEffect, useRef, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { apiUrl } from '../../common/url';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { Alert, message, Select, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

interface ReportProps {
  client: { id: string; name?: string, token: string, permissions: string[] };
}

interface ReportEmbedConfig {
  type: string;
  id: string;
  embedUrl: string;
  accessToken: string;
  tokenType: models.TokenType;
  reportName: string;
}

const Report: React.FC<ReportProps> = ({ client }) => {
  const [embedConfig, setEmbedConfig] = useState<ReportEmbedConfig>();
  const [reportIsLoading, setReportIsLoading] = useState(true);
  const [availableReports, setAvailableReports] = useState<{ report_id: string, report_name: string }[]>([]);
  const [hasNoReports, setHasNoReports] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [reportId, setReportId] = useState<string | null>(null);
  const isComponentMounted = useRef(true);


  useEffect(() => {
    setReportId(location.pathname.split("/")[4]);
  }, [location]);

  useEffect(() => {
    setReportIsLoading(true);
    setEmbedConfig(undefined);
    let timeoutId: number;

    const fetchEmbedConfig = (reportId: string) => {
      console.log('fetching embed config');
      if (!isComponentMounted) return;

      axios.get(`${apiUrl}/b2zero/${client.id}/get_pbi_embed_info_for_report/${reportId}`,
        { headers: { Authorization: `Bearer ${client.token}` } })
        .then(response => {
          const config = {
            type: 'report', // TODO: check if can be set fixed. Supported types: report, dashboard, tile, visual, qna and paginated report
            id: reportId,
            embedUrl: response.data.reportConfig[0].embedUrl,
            accessToken: response.data.accessToken,
            tokenType: models.TokenType.Embed,
            reportName: response.data.reportConfig[0].reportName,
            settings: {
              filterPaneEnabled: true,
              navContentPaneEnabled: true
            }
          }
          setEmbedConfig(config);
          setReportIsLoading(false);

          // Decode the accessToken to get the exp time
          const decodedToken = jwtDecode<{ exp?: number }>(response.data.accessToken); // Explicitly stating the expected shape of the decoded token
          const currentTime = Date.now() / 1000; // Current time in seconds

          let timeoutDuration: number;
          if (typeof decodedToken.exp === 'number') {
            const bufferTime = 300; // Buffer time in seconds (5 minutes)
            timeoutDuration = (decodedToken.exp - currentTime - bufferTime) * 1000; // timout duration, converted to milliseconds
          } else {
            timeoutDuration = 3300 * 1000; // Default to 55 minutes if exp is undefined
          }

          // Clear any existing timeout before setting a new one
          if (timeoutId) clearTimeout(timeoutId);
          // Schedule token renewal before it expires
          timeoutId = setTimeout(() => fetchEmbedConfig(reportId), timeoutDuration) as unknown as number; // Renew 5 minutes before expiration
        }).catch(error => {
          if (!isComponentMounted) return; // Check again in case of error
          message.error('Report Konfiguration konnte nicht geladen werden.', 3);
          Sentry.withScope(scope => {
            scope.setLevel('warning');
            scope.setExtra('hint', 'Report Konfiguration konnte nicht geladen werden.');
            Sentry.captureException(error);
          });
          console.log(error.message);
          setReportIsLoading(false);
          setEmbedConfig(undefined);
        });
    };

    if (reportId) fetchEmbedConfig(reportId);

    return () => {
      isComponentMounted.current = false;
      if (timeoutId) clearTimeout(timeoutId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  useEffect(() => {
    axios.get(`${apiUrl}/b2zero/${client.id}/get_reports`,
      { headers: { Authorization: `Bearer ${client.token}` } })
      .then(response => {
        setAvailableReports(response.data);
        if (!reportId) {
          if (response.data.length === 0) {
            setHasNoReports(true);
            setReportIsLoading(false);
            setEmbedConfig(undefined);
            // message.error(`Keine Reports gefunden für Client: ${client.name} (${client.id})`, 3);
          } else if (response.data.length > 0 && !location.pathname.split("/")[4]) { // if no report id in url, but reports are available
            navigate(`/client/${client.id}/report/${response.data[0].report_id}`);
            setReportIsLoading(true);
            setEmbedConfig(undefined);
          }
        }
        const reportIdFromUrl = location.pathname.split("/")[4];
        if (response.data.length > 0 && reportIdFromUrl && !response.data.some((report: { report_id: string }) => report.report_id === reportIdFromUrl)) {// if report id in url is not in available reports
          message.warning('Report ID in URL ist ihrer Firma zugeorndet. Es wird auf den ersten verfügbaren Report umgeleitet.', 3);
          console.log('Report id in ur is not assigned to the selected cliend. You are being redirected to the first available report.');
          navigate(`/client/${client.id}/report/${response.data[0].report_id}`);
          setReportIsLoading(true);
          setEmbedConfig(undefined);
        }
      }).catch(error => {
        console.log(error);
        message.error('Reports konnten nicht geladen werden');
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Reports konnten nicht geladen werden');
          Sentry.captureException(error);
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.id]);


  const handleChangeReport = (newReportId: string) => {
    setReportIsLoading(true);
    setEmbedConfig(undefined);
    setReportId(newReportId);
    navigate(`/client/${client.id}/report/${newReportId}`);
  };

  return (
    <>
      {embedConfig && (
        <PowerBIEmbed embedConfig={embedConfig} eventHandlers={
          new Map([
            ['loaded', function () { console.log('Report loaded'); }],
            ['rendered', function () { console.log('Report rendered'); }],
            ['error', function (event) { console.log(event?.detail); }]
          ])
        }
          key={embedConfig.id} />
      )}
      {(reportIsLoading && !embedConfig) && !hasNoReports && <Spin className="report-loading-spin" style={{ display: 'flex', justifyContent: 'center', marginTop: "10px" }} />}
      {!reportIsLoading && !hasNoReports && !embedConfig && <Alert message="Report konnte nicht geladen werden" type="error" />}
      {hasNoReports && <Alert message={`Keine Reports gefunden für Client: ${client.name} (${client.id})`} type="error" />}
      {(availableReports?.length > 1 || (availableReports?.length > 0 && !reportIsLoading && !embedConfig)) && (
        <div style={{ marginTop: "10px" }}>Bericht wechseln:
          <br />
          <Select
            style={{ width: 300 }}
            placeholder="Wählen Sie einen Report"
            onChange={handleChangeReport}
            value={availableReports.find(report => report.report_id === reportId)?.report_name}
          >
            {availableReports.map(report => (
              <Select.Option key={report.report_id} value={report.report_id}>{report.report_name}</Select.Option>
            ))}
          </Select>
        </div>
      )}
    </>
  );
}

export default Report;
