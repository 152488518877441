import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, message, Table, Tooltip, Typography } from 'antd';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { apiUrl } from '../../../common/url';
import { ColumnsType, ColumnType, ColumnGroupType } from 'antd/es/table';
import EditableCell from './EditableCell';
import ClientSelection from './ClientSelection';

interface DataType {
  [key: string]: any;
}

interface FixedColumn {
  client_id: number;
  name: string;
  [key: string]: any;
  isTemplate?: boolean;
  editable?: boolean;
}

interface CategoryColumn {
  name: string;
  description?: string;
  options?: any[];
}

interface CategoryData {
  title: string;
  description: string;
  columns: CategoryColumn[];
  data: DataType[];
}

interface BackendResponse {
  fixedColumns: FixedColumn[];
  categories: CategoryData[];
}

interface CustomColumnType<T> extends ColumnType<T> {
  editable?: boolean;
  options?: { label: string; value: any }[];
}

interface CustomColumnGroupType<T> extends ColumnGroupType<T> {
  children: CustomColumnType<T>[];
  editable?: boolean;
}

type CustomColumnsType<T> = (CustomColumnType<T> | CustomColumnGroupType<T>)[];

interface TimeSeriesSelectionProps {
  client: { id: string; name?: string, token: string, permissions: string[] };
  clients: { id: string; reportType?: string; name: string }[];
  adminRoles?: string[];
  adminToken?: string;
}

const TimeSeriesSelection: React.FC<TimeSeriesSelectionProps> = ({ client, clients, adminRoles, adminToken }) => {
  const [visibleCategories, setVisibleCategories] = useState<string[]>([]);
  const [data, setData] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
  const [totalWidth, setTotalWidth] = useState(0);
  const [selectedClients, setSelectedClients] = React.useState<number[]>([]);

  const handleChangeSelectedClients = (selectedClients: { label: string, value: number }[]) => {
    const selectedClientIds = selectedClients.map(user => user.value);
    setSelectedClients(selectedClientIds);
  };

  const fetchTimeseries = () => {
    let client_ids: number[] = selectedClients.length > 1 ? selectedClients : [Number(client.id)];
    const templateClientId = 1;
    let includeEditableTemplateClient = false;

    // Ensure client_id 1 is included
    if (!client_ids.includes(templateClientId)) {
      client_ids.push(templateClientId);
    } else {
      includeEditableTemplateClient = true;
    }

    const queryUrl = adminRoles?.includes("data_admin") ? `${apiUrl}/admin/clients_selected_asm/get_clients_selected_asm` : `${apiUrl}/b2zero/${client.id}/get_clients_selected_asm`;
    const authToken = adminRoles?.includes("data_admin") ? adminToken : client.token;
    axios.post(queryUrl,
      { client_ids },
      { headers: { Authorization: `Bearer ${authToken}` } }
    ).then(response => {
      const { fixedColumns, categories } = response.data as BackendResponse;
      const fixedColumnsDef: CustomColumnsType<DataType> = Object.keys(fixedColumns[0]).map((key, index) => ({
        title: key,
        dataIndex: key,
        key: `fixed-${key}-${index}`,
        fixed: 'left' as 'left',
        width: key === 'client_id' ? 75 : 200,
      }));

      const categoryColumns: CustomColumnsType<DataType> = categories.map((category) => ({
        title: (
          <Tooltip title={category.description}>
            {category.title}
          </Tooltip>
        ),
        key: category.title,
        children: category.columns.map(col => ({
          title: (
            <Tooltip title={col.description}>
              {col.name}
            </Tooltip>
          ),
          dataIndex: col.name,
          key: `${category.title}-${col.name}`,
          editable: true,
          options: col.options?.map(option => ({ label: option, value: option })) || [],
        }))
      }));

      let data = fixedColumns.map((fixedCol, fixedColIndex) => ({
        ...fixedCol,
        key: `fixedCol-${fixedCol.client_id}-${fixedColIndex}`,
        ...categories.reduce((acc, category) => {
          const categoryData = category.data.find(data => data.client_id === fixedCol.client_id);
          return { ...acc, ...categoryData };
        }, {})
      }));

      const templateClientIndex = data.findIndex(row => row.client_id === templateClientId);

      if (templateClientIndex !== -1) {
        if (includeEditableTemplateClient) {
          // Copy the result data for template client and modify the copy
          const templateRow = {
            ...data[templateClientIndex],
            client_id: -1,
            key: `template`,
            editable: false,
            isTemplate: true
          };
          data.unshift(templateRow); // Add the template row to the beginning
        } else {
          // Modify the existing data for client_id templateClientId
          data[templateClientIndex] = {
            ...data[templateClientIndex],
            key: `template`,
            editable: false,
            isTemplate: true
          };
          // Move the modified template row to the beginning
          const [templateRow] = data.splice(templateClientIndex, 1);
          data.unshift(templateRow);
        }
      } else {
        message.warning('Keine Template Daten verfügbar. Bitte überprüfen Sie die Datenbank.');
      }

      // Check for missing client data and copy from template
      client_ids.forEach(clientId => {
        if (clientId !== templateClientId) {
          const clientDataIndex = data.findIndex(row => row.client_id === clientId);
          if (clientDataIndex === -1 && templateClientIndex !== -1) {
            const newClient = clients.find(c => Number(c.id) === clientId);
            const newClientData = {
              ...data[templateClientIndex],
              client_id: clientId,
              name: newClient ? newClient.name : `Client ${clientId}`, // Ensure name is always a string
              key: `new-${clientId}`,
              isTemplate: false,
              isNew: true
            };
            data.push(newClientData);
          }
        }
      });

      setColumns([...fixedColumnsDef, ...categoryColumns]);
      setData(data);
      const visibleCategoriesNew = categories.length > 0 ? [categories[0].title] : [];
      setVisibleCategories(visibleCategoriesNew);

      setTotalWidth(calculateTotalWidth(visibleCategoriesNew))
    })
      .catch(error => {
        message.error('Fehler beim Abruf der Zeitreihen Daten: ' + error.message);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Abrufen der Zeitreihen Daten: ' + error.message);
          Sentry.captureException(error);
        });
      });
  }

  useEffect(() => {
    fetchTimeseries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.id, selectedClients]);

  const handleSave = (row: DataType) => {
    const newData = [...data];
    const index = newData.findIndex(item => row.key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      setData(newData);
    } else {
      newData.push(row);
      setData(newData);
    }
  };

  const hasChildren = (col: CustomColumnType<DataType> | CustomColumnGroupType<DataType>): col is CustomColumnGroupType<DataType> => {
    return (col as CustomColumnGroupType<DataType>).children !== undefined;
  };

  const mergedColumns = columns.map(col => {
    if (!hasChildren(col)) {
      return col;
    }
    return {
      ...col,
      children: col.children.map((child: CustomColumnType<DataType>) => ({
        ...child,
        onCell: (record: DataType) => ({
          record,
          editable: child.editable,
          dataIndex: child.dataIndex,
          title: child.title,
          handleSave,
          options: child.options,
        }),
      })),
    };
  });

  const filteredColumns = [
    ...mergedColumns.filter((col) =>
      hasChildren(col) ? visibleCategories.includes(col.key as string) : true
    ),
    {
      title: 'Aktion',
      key: 'action',
      fixed: 'right' as 'right',
      width: 120,
      render: (_: any, record: DataType) => {
        if (!record.isTemplate) {
          if (record.isNew) {
            return (
              <Button type="primary" onClick={() => createClientsSelectedRow(record)}>
                Erstellen
              </Button>
            );
          } else {
            return (
              <Button type="primary" onClick={() => saveChanges(record)}>
                Speichern
              </Button>
            );
          }
        }
        return null;
      },
    },
  ];


  const calculateTotalWidth = (visibleCategories: string[]) => {
    const fixedColumnsWidth = columns
      .filter(col => !hasChildren(col))
      .reduce((acc, col) => {
        if (col.title === 'key') {
          return acc + 75;
        }
        return acc + 200;
      }, 0);

    const visibleCategoryColumnsWidth = columns
      .filter(col => hasChildren(col) && visibleCategories.includes(col.key as string))
      .reduce((acc, col) => {
        if (hasChildren(col)) {
          return acc + (col.children?.length || 0) * 200;
        }
        return acc;
      }, 0);

    return fixedColumnsWidth + visibleCategoryColumnsWidth;
  };

  const saveChanges = (row: DataType) => {
    const { key, isTemplate, isNew, editable, ...dataToSend } = row;
    const queryUrl = Number(client.id) === row.client_id ? `${apiUrl}/b2zero/${client.id}/update_client_selected_asm` : `${apiUrl}/admin/clients_selected_asm/update_client_selected_asm`;
    const authToken = Number(client.id) === row.client_id ? client.token : adminToken;
    axios.post(queryUrl, dataToSend, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        message.success('Änderungen erfolgreich gespeichert.');
      })
      .catch((error) => {
        message.error('Fehler beim Speichern der Änderungen: ' + error.message, 5);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Error while saving data:' + error.message);
          scope.setExtra('row', row);
          scope.setExtra('queryUrl', queryUrl);
          Sentry.captureException(error);
        });
      });
  };

  const createClientsSelectedRow = (row: DataType) => {
    const { key, isTemplate, isNew, editable, ...dataToSend } = row;
    const queryUrl = Number(client.id) === row.client_id ? `${apiUrl}/b2zero/${client.id}/create_client_selected_asm` : `${apiUrl}/admin/clients_selected_asm/create_client_selected_asm`;
    const authToken = Number(client.id) === row.client_id ? client.token : adminToken;
    axios.post(queryUrl, dataToSend, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        message.success('Änderungen erfolgreich gespeichert.');
      })
      .catch((error) => {
        message.error('Fehler beim Speichern der Änderungen: ' + error.message, 5);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Error while saving data:' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  return (
    client === undefined ? <div>Please select a client.</div> :
      <>
        {(clients.length < 2 || !adminRoles?.includes("data_admin")) && (
          <Typography.Title level={1}>Auswahl Zeitreihen für {client.name}</Typography.Title>
        )}

        {clients?.length > 1 && adminToken && adminRoles?.includes("data_admin") && (
          <>
            <Typography.Title level={1}>Auswahl Zeitreihen</Typography.Title>
            <ClientSelection
              client={client}
              clients={clients}
              handleChangeSelectedClients={handleChangeSelectedClients}
              adminToken={adminToken}
            />
          </>
        )}
        <style>
          {`
          .template-row td {
            background-color: #f0f0f0 !important; /* Light gray background color */
          }
        `}
        </style>
        <Table
          columns={filteredColumns as (ColumnGroupType<DataType> | ColumnType<DataType>)[]}
          dataSource={data}
          rowKey="client_id"
          bordered
          scroll={{ x: totalWidth }}
          rowClassName={(record) => record.isTemplate ? 'template-row' : ''}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          title={() => (
            <>
              <Typography.Title level={3} style={{ margin: 0 }}>
                Zeitreihen
              </Typography.Title>
              <Form.Item label="Kategorien" style={{ marginBottom: 0 }}>
                <Checkbox.Group
                  options={columns.filter(hasChildren).map((col, index) => ({
                    label: col.title as React.ReactNode,
                    value: col.key as string,
                    key: `checkbox-${index}`
                  }))}
                  value={visibleCategories}
                  onChange={(checkedValues) => {
                    setVisibleCategories(checkedValues as string[]);
                    setTotalWidth(calculateTotalWidth(checkedValues));
                  }}
                />
              </Form.Item>
            </>
          )}
        />
      </>
  );
};

export default TimeSeriesSelection;
