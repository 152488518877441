import React, { FC, useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

interface WelcomeProps {
  clients?: { id: string; name: string; reportType: string }[];
}
const Welcome: FC<WelcomeProps> = ({clients}) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest).then(() => {
      }).catch(error => {
        if (error instanceof InteractionRequiredAuthError && error.errorCode === 'interaction_in_progress') {
          console.log('Interaction is already in progress');
        } else {
          // Handle other errors
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>Willkommen!</h1>
      {isAuthenticated ? (<>{clients && clients.length > 1 && <>Bitte wählen Sie einen Kunden aus.</>}</>) : (<>Bitte melden Sie sich an.</>)}
    </div>
  );
};

export default Welcome;
