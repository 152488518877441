import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const SentryRouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const anonymizeClientId = (path: string) => {
      return path.replace(/\/client\/[^/]+/, '/client/<id>');
    };

    const anonymizedPath = anonymizeClientId(location.pathname);
    Sentry.setTag('route', anonymizedPath);
  }, [location]);

  return null;
};

export default SentryRouteTracker;
