import { Button, Form, Input, InputNumber, Select, Tooltip } from 'antd';
import React from 'react';
import { DataCollRowFragment } from './types/DataCollRowFragment';


interface MeasureFormProps {
  measure: DataCollRowFragment;
  areaTotal?: number;
  handleSaveMeasure: (key: string, scenario: string, index: number) => void;
  isLoading: boolean;
  handleChangeMeasureFormItem: (
    key: string,
    scenario: string,
    index: number,
    label: string,
    value: any
  ) => void;
}

const MeasureForm: React.FC<MeasureFormProps> = ({
  measure,
  areaTotal,
  handleSaveMeasure,
  isLoading,
  handleChangeMeasureFormItem,
}) => {

  return (
    <>
      <Form
        layout='inline'
        onFinish={() => handleSaveMeasure(measure.key, measure.scenario, measure.index)}
      >
        {measure.data?.map((formItem) => (
          <Form.Item
            key={`${measure.key}-${measure.scenario}-${measure.index}-${formItem.label}`}
            label={formItem.label}
            tooltip={formItem.description && formItem.description}
            style={{ lineHeight: '40px' }}
          >
            {formItem.type === 'number' && (
              <Tooltip title={formItem.label === "Kosten (€)" && areaTotal && `Kosten (€)/m2: ${formItem.value ? (formItem.value / areaTotal).toFixed(2) : ''}`}>
                <InputNumber
                  value={formItem.value}
                  changeOnWheel
                  onChange={(value) =>
                    handleChangeMeasureFormItem(
                      measure.key,
                      measure.scenario,
                      measure.index,
                      formItem.label,
                      value
                    )
                  }
                />
              </Tooltip>
            )}
            {formItem.type === 'selection' && formItem.options && (
              <Select
                showSearch
                style={{ width: 250 }}
                value={formItem.value}
                options={formItem.options.map((option) => ({
                  value: option,
                  label: option,
                }))}
                popupMatchSelectWidth={true}
                onChange={(value) =>
                  handleChangeMeasureFormItem(
                    measure.key,
                    measure.scenario,
                    measure.index,
                    formItem.label,
                    value
                  )
                }
                allowClear
              > {formItem.options.map((option) => (
                <Select.Option key={option} value={option}>
                  <Tooltip title={option}>
                    {option}
                  </Tooltip>
                </Select.Option>
              ))}
              </Select>
            )}
            {(formItem.type === 'text' ||
              (formItem.type === 'selection' && !formItem.options)) && (
                <Input
                  value={formItem.value}
                  onChange={(e) =>
                    handleChangeMeasureFormItem(
                      measure.key,
                      measure.scenario,
                      measure.index,
                      formItem.label,
                      e.target.value
                    )
                  }
                allowClear
                />
              )}
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} style={{ marginTop: "5px" }}>
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MeasureForm;
