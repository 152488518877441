import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface Coefficient {
  label: string;
  value: number;
}

interface CO2TargetChartProps {
  coefficients: Coefficient[];
}

const CO2TargetChart: React.FC<CO2TargetChartProps> = ({ coefficients }) => {
  const scenarios = {
    A: coefficients.filter(c => c.label.startsWith('a_')),
    B: coefficients.filter(c => c.label.startsWith('b_')),
    C: coefficients.filter(c => c.label.startsWith('c_'))
  };

  const calculateTarget = (year: number, coeffs: Coefficient[]) => {
    const x = (year - 2000) / 10;
    const x1 = coeffs.find(c => c.label.endsWith('x1'))?.value || 0;
    const x2 = coeffs.find(c => c.label.endsWith('x2'))?.value || 0;
    const x3 = coeffs.find(c => c.label.endsWith('x3'))?.value || 0;
    const x4 = coeffs.find(c => c.label.endsWith('x4'))?.value || 0;
    const tc = coeffs.find(c => c.label.endsWith('tc'))?.value || 1;
    const target = (x1 * x ** 3 - x2 * x ** 2 + x3 * x - x4) * tc;
    return Number(target.toFixed(3));
  };

  const generateData = (startYear: number, endYear: number, coeffs: Coefficient[]) => {
    const data = [];
    for (let year = startYear; year <= endYear; year++) {
      data.push({ year, target: calculateTarget(year, coeffs) });
    }
    return data;
  };

  const startYear = 2024;
  const endYear = 2050;
  const dataA = generateData(startYear, endYear, scenarios.A);
  const dataB = generateData(startYear, endYear, scenarios.B);
  const dataC = generateData(startYear, endYear, scenarios.C);

  const combinedData = dataA.map((item, index) => ({
    year: item.year,
    targetA: item.target,
    targetB: dataB[index]?.target,
    targetC: dataC[index]?.target,
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={combinedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="year"
          interval={1}
          label={{ value: 'Jahr', position: 'insideBottomRight', offset: -5 }}
          padding={{ right: 20 }}
        />
        <YAxis label={{ value: 'Ziel spezifische\nCO2-Emissionen (kg/m²a)', angle: -90, position: 'insideLeft', dy: 80, offset: 10 }} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="targetA" name="Scenario A" stroke="#8884d8" />
        <Line type="monotone" dataKey="targetB" name="Scenario B" stroke="#82ca9d" />
        <Line type="monotone" dataKey="targetC" name="Scenario C" stroke="#ffc658" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CO2TargetChart;
