import React, { useState, useEffect } from 'react';
import { Input, Form, Select, Tooltip } from 'antd';

interface DataType {
  [key: string]: any;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: DataType;
  handleSave: (record: DataType) => void;
  options?: { label: string; value: any }[];
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  options,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editing) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [editing, form, dataIndex, record]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable && !record?.isTemplate) {
    childNode = editing ? (
      <Form form={form} style={{ margin: 0 }} initialValues={{ [dataIndex]: record[dataIndex] }}>
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true, message: `${title} is required.` }]}
        >
          {options ? (
            <Select onBlur={save} onChange={save} dropdownStyle={{ width: 300 }}>
              {options.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input onPressEnter={save} onBlur={save} />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  } else if (record?.isTemplate) {
    childNode = (
      <Tooltip
        title={
          options ? (
            <div>
              {options.map((option, index) => (
                <div key={index}>{option.label}</div>
              ))}
            </div>
          ) : ''
        }>
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }}>
          {children}
        </div>
      </Tooltip >
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
