import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, message, Select, Tooltip, TreeDataNode, TreeSelect } from 'antd';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { apiUrl } from '../../common/url';
import { DataCollRow } from './types/DataCollRow';

interface FacilitySelectionProps {
  client: { id: string; name?: string, token: string, permissions: string[] };
  dataCollYear?: number;
  setDataCollYear: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedDataCollEntries: string[];
  setSelectedDataCollEntries: React.Dispatch<React.SetStateAction<string[]>>;
  selectedKeys: string[];
  setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;
}
const FacilitySelection: React.FC<FacilitySelectionProps> = ({ client, dataCollYear, setDataCollYear, selectedDataCollEntries, setSelectedDataCollEntries, selectedKeys, setSelectedKeys }) => {
  const [dataCollYears, setDataCollYears] = useState<{ label: string, value: number }[]>([]);
  const [dataCollEntries, setDataCollEntries] = useState<TreeDataNode[]>([]);
  const [isTreeSelectFocused, setIsTreeSelectFocused] = useState(false);


  useEffect(() => {
    setSelectedDataCollEntries([]);
    setSelectedKeys([]);
    setDataCollYear(undefined);
    setDataCollEntries([]);
    getDataCollYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.id]);

  const getDataCollYears = () => {
    axios.get(`${apiUrl}/b2zero/${client.id}/get_data_coll_years`, {
      headers: { Authorization: `Bearer ${client.token}` }
    })
      .then(response => {
        const years = response.data;
        setDataCollYears(years.map((year: number) => ({ label: year.toString(), value: year })));
        if (years.length > 0) {
          handleChangeYear(Math.max(...years));
        }
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der verfügbaren Startjahre: ', error.message);
        return [];
      });
  }

  const createTreeData = (dataCollEntries: DataCollRow[]) => {
    // Collect wes (WirtschaftsEinheiten)
    const wes: string[] = [];
    for (const dataCollEntry of dataCollEntries) {
      const we: string = dataCollEntry.we;
      if (wes.indexOf(we) <= -1) { // we is not present in wes
        wes.push(we);
      }
    }
    wes.sort();
    // create 1st level of treeData using the prefixes
    const treeDataBuilt = wes.map(we => ({
      title: `WE: ${we}`,
      value: `WE: ${we}`,
      key: `WE: ${we}`,
      children: [] as any[],
    }));
    // create 2nd level of treeData by adding streets to the wes
    for (const dataCollEntry of dataCollEntries) {
      for (const element of treeDataBuilt) {
        if (`WE: ${dataCollEntry.we}` === element.value) {
          element.children.push({
            title: `WE: ${dataCollEntry.we} - ${dataCollEntry.street} - Key: ${dataCollEntry.key}`,
            value: `${element.key} - ${dataCollEntry.street} - Key: ${dataCollEntry.key}`,
            key: `${element.key} - ${dataCollEntry.street} - Key: ${dataCollEntry.key}`,
          });
          break;
        }
      }
    }

    // elements that have exactly one child, are replaced by their child
    for (const element of treeDataBuilt) {
      if (element.children.length === 1) {
        element.title = element.children[0].title;
        element.value = element.children[0].value;
        element.key = element.children[0].key;
        element.children = [];
      }
    }

    return treeDataBuilt;
  };


  const handleChangeYear = (year: number) => {
    setDataCollYear(year);
    axios.get(`${apiUrl}/b2zero/${client.id}/get_bottom_up_data_coll`, {
      params: {
        year: year,
        clientId: client.id,
        includeDataCollFragments: false
      },
      headers: { Authorization: `Bearer ${client.token}` }
    })
      .then(response => {
        const dataCollRowsSelectedYear = response.data;
        setDataCollEntries(createTreeData(dataCollRowsSelectedYear));
      })
      .catch(error => {
        console.error('Error fetching data:', error.message);
        message.error('Fehler beim Abrufen der Anlagen: ' + error.message, 7);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Abrufen der Anlagen: ' + error.message);
          Sentry.captureException(error);
        });
      })
  };

  const handleChangeTreeSelection = (treeSelection: string[]) => {
    const selectedKeysNew: string[] = treeSelection.map(str => { // get keys from treeSelection.
      const match = str.match(/Key: (.*)$/);
      return match ? match[1] : '';
    }).filter(Boolean); // Exclude nulls and empty strings

    setSelectedKeys(selectedKeysNew);
    setSelectedDataCollEntries(treeSelection);
  };

  const handleFocus = () => {
    setIsTreeSelectFocused(true);
  };

  const handleBlur = () => {
    setIsTreeSelectFocused(false);
  };

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(selectedDataCollEntries.join('; '));
    message.success('Auswahl wurde in die Zwischenablage kopiert');
  }, [selectedDataCollEntries]);

  const handlePaste = useCallback(async () => {
    try {
      // Read the clipboard
      const text = await navigator.clipboard.readText();
      const clipboardSelection = text.split('; ').map(str => str.trim());

      // Filter the clipboard selection to only include options that are available in dataCollEntries
      const availableSelection = clipboardSelection.filter(str => {
        return dataCollEntries.some(entry => entry.key === str);
      }).filter(Boolean); // Exclude nulls and empty strings

      // List all unmatched entries in a warning message
      const unmatchedSelection = clipboardSelection.filter(str => !availableSelection.includes(str));
      if (unmatchedSelection.length > 0) {
        message.warning(`Folgende Einträge wurden nicht gefunden: ${unmatchedSelection.join(', ')}. Bitte überprüfen Sie ob das richtige Startjahr ausgewählt wurde.`);
      }

      // Update the state of the component
      const selectedKeysNew: string[] = availableSelection.map(str => {
        const match = str.match(/Key: (.*)$/);
        return match ? match[1] : '';
      }).filter(Boolean);
      if (selectedKeysNew.length > 0) {
        setSelectedDataCollEntries(availableSelection);
        setSelectedKeys(selectedKeysNew);
        message.success('Auswahl aus der Zwischenablage wurde übernommen');
      }
    } catch (err) {
      message.error('Fehler beim Lesen der Zwischenablage.', 4);
      Sentry.captureException(err);
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCollEntries]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && isTreeSelectFocused) {
        if (event.key === 'c') {
          handleCopy();
        }
      }
    };

    const handlePasteEvent = (event: ClipboardEvent) => {
      if (isTreeSelectFocused) {
        event.preventDefault();
        handlePaste();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('paste', handlePasteEvent);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('paste', handlePasteEvent);
    };
  }, [handleCopy, handlePaste, isTreeSelectFocused]);

  return (
    <>
      <Form.Item label="Startjahr" style={{ marginBottom: 0 }}>
        <Select
          placeholder="Bitte wählen Sie ein Jahr"
          style={{ width: 200 }}
          onChange={handleChangeYear}
          options={dataCollYears}
          value={dataCollYear}
        />
      </Form.Item>
      <Tooltip
        title="Strg + c kopiert die aktuelle Auswahl in die Zwischenablage. Strg + v übernimmt die Auswahl aus der Zwischenablage"
        placement="right"
      >
        <TreeSelect
          onFocus={handleFocus}
          onBlur={handleBlur}
          treeData={dataCollEntries}
          value={selectedDataCollEntries}
          labelInValue={false}
          onChange={handleChangeTreeSelection}
          treeCheckable={true}
          // showCheckedStrategy={TreeSelect.SHOW_PARENT} would require logic in createTableData()
          placeholder={"Bitte Anlagen auswählen"}
          allowClear={true}
          style={{ marginTop: "12px", width: '555px' }}
          showSearch
          treeDefaultExpandAll
        />
      </Tooltip>
      <br />
      <Button style={{ marginRight: "5px", marginTop: "5px" }} onClick={handleCopy}>Auswahl in Zwischenablage kopieren</Button>
      <Tooltip
        title="Bitte zuerst das Startjahr auswählen."
        placement="right"
      >
        <Button onClick={handlePaste}>Auswahl aus Zwischenablage übernehmen</Button>
      </Tooltip>
    </>
  );
}

export default FacilitySelection;
