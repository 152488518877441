import React, { FC, useEffect, useState } from 'react';
import { Card, List, Form, Input, Button, message, Modal } from 'antd';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { apiUrl } from '../../common/url';


interface ReportAdministrationProps {
  client: { client_id: number, name: string };
  adminToken: string;
}

interface ReportType {
  report_id: string;
  client_id: number;
  report_name: string;
}

const ReportAdministration: FC<ReportAdministrationProps> = ({ client, adminToken }) => {
  const [reports, setReports] = useState<ReportType[]>([]);

  useEffect(() => {
    axios.get(`${apiUrl}/admin/manage_clients/${client.client_id}/get_all_reports`,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        setReports(response.data);
      }).catch((error) => {
        message.error('Fehler beim Laden der Berichte: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Laden der Berichte: ' + error.message);
          Sentry.captureException(error);
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.client_id]);

  const saveReport = (values: { report_id: string, report_name: string }) => {
    axios.post(`${apiUrl}/admin/manage_clients/${client.client_id}/update_report`,
      {
        report_id: values.report_id,
        client_id: client.client_id,
        report_name: values.report_name
      },
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        message.success('Bericht aktualisiert.', 3);
      }).catch((error) => {
        message.error('Fehler beim Aktualisieren des Berichts: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Aktualisieren des Berichts: ' + error.message);
          Sentry.captureException(error);
        });
      })
  }

  const handleDeleteDialog = (report_id: string) => {
    const reportToDelete = reports.find(report => report.report_id === report_id);
    Modal.confirm({
      title: 'Soll dieser Report wirklich gelöscht werden?',
      content: `client_id: ${client?.client_id}, report_id: ${reportToDelete?.report_id} report_name: ${reportToDelete?.report_name}); Diese Aktion kann nicht rückgängig gemacht werden.`,
      onOk() {
        handleDelete(report_id);
      },
      onCancel() {
        message.info('Löschen abgebrochen', 3);
      },
    });
  };

  const handleDelete = (report_id: string) => {
    axios.post(`${apiUrl}/admin/manage_clients/${client.client_id}/delete_report/${report_id}`, null,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        setReports(reports.filter(report => report.report_id !== report_id));
        message.success('Bericht gelöscht.', 3);
      }).catch((error) => {
        message.error('Fehler beim Löschen des Berichts: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Löschen des Berichts: ' + error.message);
          Sentry.captureException(error);
        });
      });
  }

  const createReport = (values: { report_id: string, report_name: string }) => {
    axios.post(`${apiUrl}/admin/manage_clients/${client.client_id}/create_report`,
      {
        report_id: values.report_id,
        client_id: client.client_id,
        report_name: values.report_name
      },
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        setReports([...reports, response.data]);
        message.success('Bericht erstellt.', 3);
      }).catch((error) => {
        message.error('Fehler beim Erstellen des Berichts: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Erstellen des Berichts: ' + error.message);
          Sentry.captureException(error);
        });
      });
  }

  return (
    <Card title={`Berichte von ${client?.name} (${client.client_id})`} style={{ width: "700px" }}>
      <List
        itemLayout="horizontal"
        dataSource={reports}
        renderItem={(item, index) => (
          <List.Item key={`${index}-${item.client_id}-${item.report_id}`}>
            <Form
              key={`form-${client.client_id}-${index}`}
              name={`report-${item.report_id}`}
              labelCol={{ span: 6 }}
              autoComplete="off"
              initialValues={{ report_id: item.report_id, report_name: item.report_name, client_id: item.client_id }}
              onFinish={(values) => saveReport(values)}
            >
              <Form.Item
                name="report_id"
                label="Report ID"
                rules={[{ required: true, message: 'Bitte einen Wert eintragen!' }]}
              >
                <Input style={{ width: 400 }} />
              </Form.Item>
              <Form.Item
                name="report_name"
                label="Report Name"
                rules={[{ required: true, message: 'Bitte einen Wert eintragen!' }]}
              >
                <Input style={{ width: 400 }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Speichern
                </Button>
                <Button danger onClick={() => handleDeleteDialog(item.report_id)} style={{ marginLeft: "10px" }}>
                  Löschen
                </Button>
              </Form.Item>
            </Form>
          </List.Item>
        )}
      />
      <Card title="Bericht anlegen" style={{ width: "650px" }}>
        <Form
          name="manage-reports"
          labelCol={{ span: 6 }}
          autoComplete="off"
          onFinish={(values) => createReport(values)}
        >
          <Form.Item
            name="report_id"
            label="Report ID"
            rules={[{ required: true, message: 'Bitte einen Wert eintragen!' }]}
          >
            <Input style={{ width: 400 }} />
          </Form.Item>
          <Form.Item
            name="report_name"
            label="Report Name"
            rules={[{ required: true, message: 'Bitte einen Wert eintragen!' }]}
          >
            <Input style={{ width: 400 }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Report
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Card>
  );
};

export default ReportAdministration;
