/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: enable no-unused-vars
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, List, message, Modal, Select, Tooltip, TreeSelect, Typography } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { apiUrl } from '../../common/url';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useMsal } from '@azure/msal-react';
import UserSelection from './UserSelection';
import BulkUserAdministration from './BulkUserAdministration';

interface UserType {
  azureObjectId: string;
  email: string;
  name?: string;
  blockSignin?: boolean;
  availableClientRoles?: string[];
  clientMemberships?: { clientName: string, userId?: string, clientId: string, roles?: string[] }[]; // if no membership active only clientId, else all
  availableAdminRoles?: string[];
  adminRoles?: string[];
  adminEmail?: string;
}

interface AdministrationProps {
  adminRoles: string[];
  adminToken: string;
}

const Administration: React.FC<AdministrationProps> = ({ adminRoles, adminToken }) => {
  const { instance } = useMsal();
  const [userWithRoles, setUserWithRoles] = useState<UserType>();
  const [users, setUsers] = useState<{ azureObjectId: string, email: string }[]>([]);
  const [userClientMemberships, setUserClientMemberships] = useState<{ id?: string, clientName: string, userId?: string, clientId: string, roles?: string[] }[]>([]);
  const [lastAddedUser, setLastAddedUser] = useState<{ label: string; value: string }>();
  const [selectedUsers, setSelectedUsers] = useState<{ label: string; value: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios.get(`${apiUrl}/admin/manage_users/get_all_users`,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        setUsers(response.data);
      }).catch((error) => {
        console.log(error.response.data);
        message.error('Fehler beim Laden der Benutzer', 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Laden der Benutzer');
          Sentry.captureException(error);
        });
      });
  }, [adminToken]);

  useEffect(() => {
    if (lastAddedUser) {
      handleChangeSelectedUsers([lastAddedUser]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAddedUser]);

  const handleChangeUser = (userId?: string | { label: string, value: string }) => {
    if (!userId) {
      setUserWithRoles(undefined);
      setUserClientMemberships([]);
      return
    };

    let user: UserType | undefined;
    if (typeof userId === 'object' && 'value' in userId) {
      user = { azureObjectId: userId.value, email: userId.label };
    } else {
      user = users.find((user) => user.azureObjectId === userId);
      if (!user) return;
    }

    axios.get(`${apiUrl}/admin/manage_users/get_user/${user.azureObjectId}`,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        const { data } = response;
        setUserWithRoles(data);
        setUserClientMemberships(response.data.clientMemberships);
        setSelectedUsers([{ label: data.email, value: data.azureObjectId }]);
      }).catch((error) => {
        message.error('Fehler beim Laden des Benutzers', 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Laden des Benutzers');
          Sentry.captureException(error);
        });
      });
  };

  const handleChangeSelectedUsers = (selectedUsersNew: { label: string, value: string }[]) => {
    setSelectedUsers(selectedUsersNew);
    if (selectedUsersNew.length === 0) {
      setUserWithRoles(undefined);
      setUserClientMemberships([]);
    } else if (selectedUsersNew.length === 1) {
      handleChangeUser(selectedUsersNew[0]);
    } else {
      // Multiple users selected
      setUserWithRoles(undefined);
      setUserClientMemberships([]);
    }
  };

  const updateUserClientRoles = (roles: any, clientId: string) => {
    if (!userWithRoles) return;
    const rolesWithoutClientId = Object.keys(roles).reduce((acc, key) => {
      if (key.startsWith(clientId)) {
        return { ...acc, [key.replace(`${clientId}-`, '')]: roles[key] !== undefined ? roles[key] : false };
      }
      return acc;
    }, {});

    console.log(rolesWithoutClientId)
    axios.post(`${apiUrl}/admin/manage_users/update_user_client_membership`,
      { user_id: userWithRoles.azureObjectId, client_id: clientId, ...rolesWithoutClientId },
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        console.log(response.data);
        message.success('Benutzer-Client-Membership aktualisiert', 3);
      }).catch((error) => {
        console.log(error.response.data);
        message.error('Fehler beim Aktualisieren der Benutzer-Client-Membership', 6);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Aktualisieren der Benutzer-Client-Membership');
          Sentry.captureException(error);
        });
      });
  }

  const addUserClientRolesForAllClients = (values: any) => {
    if (!userWithRoles) return;
    // only the values that are selected set to true, remove the prefix 'add-all-' from key
    const selectedRolesArray: string[] = Object.keys(values).filter((key) => values[key] === true).map((key) => key.replace('add-all-', ''));
    axios.post(`${apiUrl}/admin/manage_users/add_user_client_memberships_for_all_clients`,
      { user_id: userWithRoles.azureObjectId, roles_to_add: selectedRolesArray },
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then(() => {
        message.success('Benutzer-Client-Memberships für alle Clients ergänzt', 3);
        handleChangeUser(userWithRoles.azureObjectId);
      }).catch((error) => {
        message.error('Fehler beim Ergänzen der Benutzer-Client-Memberships', 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Ergänzen der Benutzer-Client-Memberships');
          Sentry.captureException(error);
        });
      });
  }

  const revokeUserClientRolesForAllClients = (values: any) => {
    if (!userWithRoles) return;
    const selectedRolesArray: string[] = Object.keys(values).filter((key) => values[key] === true).map((key) => key.replace('revoke-all-', ''));

    axios.post(`${apiUrl}/admin/manage_users/revoke_user_client_memberships_for_all_clients`,
      { user_id: userWithRoles.azureObjectId, roles_to_revoke: selectedRolesArray },
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        console.log(response.data);
        message.success('Benutzer-Client-Memberships für alle Clients entfernt', 3);
        handleChangeUser(userWithRoles.azureObjectId);
      }).catch((error) => {
        console.log(error.response.data);
        message.error('Fehler beim Entfernen der Benutzer-Client-Memberships', 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Entfernen der Benutzer-Client-Memberships');
          Sentry.captureException(error);
        });
      });
  }

  const createUser = (values: UserType) => {
    const newUser = {
      ...values as UserType,
      adminEmail: instance.getActiveAccount()?.username
    }
    axios.post(`${apiUrl}/admin/manage_users/create_user`,
      newUser,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        console.log(response.data);
        message.success('Benutzer erstellt', 3);
        setUsers([...users, { azureObjectId: response.data.azure_object_id, email: response.data.email }]);
        setLastAddedUser({ label: response.data.email, value: response.data.azure_object_id });
      }).catch((error) => {
        let errorMessage = error.message;
        if (error.response && error.response.data && error.response.data && error.response.data.message) {
          errorMessage += `, message: ${error.response.data.message}`;
        }
        console.log(errorMessage);
        message.error('Fehler beim Erstellen des Benutzers: ' + errorMessage, 6);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Erstellen des Benutzers: ' + errorMessage);
          Sentry.captureException(error);
        });
      });
  };

  const updateUser = (values: UserType) => {
    const updatedUser = values as UserType;
    if (!userWithRoles?.azureObjectId) {
      message.error('Fehler beim Aktualisieren des Benutzers: Benutzer ID ist nicht verfügbar. Bitte Seite aktualisieren und erneut versuchen.', 3);
      return;
    }
    updatedUser.azureObjectId = userWithRoles?.azureObjectId;
    console.log(updatedUser);
    axios.post(`${apiUrl}/admin/manage_users/update_user`,
      updatedUser,
      { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        console.log(response.data);
        message.success('Benutzer aktualisiert', 3);
      }).catch((error) => {
        console.log(error.message);
        message.error('Fehler beim Aktualisieren des Benutzers: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Aktualisieren des Benutzers: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  const showDeleteConfirm = (azureObjectId: string) => {
    Modal.confirm({
      title: 'Benutzer löschen',
      content: 'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nein',
      onOk() {
        handleDeleteUser(azureObjectId);
      },
      onCancel() {
        console.log('Cancel delete');
      },
    });
  };

  const handleDeleteUser = (azureObjectId: string) => {
    if (!azureObjectId) {
      message.error('Fehler beim Löschen des Benutzers: Benutzer ID ist nicht verfügbar. Bitte erneut versuchen.', 3);
      return;
    }
    axios.post(`${apiUrl}/admin/manage_users/delete_user`, { azureObjectId }, { headers: { Authorization: `Bearer ${adminToken}` } })
      .then(() => {
        message.success('Benutzer gelöscht', 6);
        setUsers(users.filter(user => user.azureObjectId !== azureObjectId));
        handleChangeUser(undefined);
        handleChangeSelectedUsers([]);
      }).catch((error) => {
        console.log(error.message);
        message.error('Fehler beim Löschen des Benutzers: ' + error.message, 3);
        Sentry.withScope(scope => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Löschen des Benutzers: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  const generateRandomPassword = () => {
    const length = 10;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?";
    const specialChars = "!@#$%^&*()_-+=<>?";
    const digits = "0123456789";
    const lowerCase = "abcdefghijklmnopqrstuvwxyz";
    const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let password = "";

    // Ensure at least one character from each required set
    password += specialChars[Math.floor(Math.random() * specialChars.length)];
    password += digits[Math.floor(Math.random() * digits.length)];
    password += lowerCase[Math.floor(Math.random() * lowerCase.length)];
    password += upperCase[Math.floor(Math.random() * upperCase.length)];

    // Fill the rest of the password length with random characters from the charset
    for (let i = 4; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    // Shuffle the password to ensure randomness
    password = password.split('').sort(() => 0.5 - Math.random()).join('');

    return password;
  }


  const validatePassword = (_: RuleObject, value: StoreValue): Promise<void> => {
    const errors: string[] = [];

    if (!/[A-Z]/.test(value)) {
      errors.push('Das Passwort muss mindestens einen Großbuchstaben enthalten.');
    }
    if (!/[a-z]/.test(value)) {
      errors.push('Das Passwort muss mindestens einen Kleinbuchstaben enthalten.');
    }
    if (!/\d/.test(value)) {
      errors.push('Das Passwort muss mindestens eine Zahl enthalten.');
    }
    if (!/[!@#$%^&*()_\-+=<>?]/.test(value)) {
      errors.push('Das Passwort muss mindestens ein Sonderzeichen enthalten (z.B. !, @, #, $, %, ^, &, *, (, ), _, -, +, =, <, >, ?).');
    }
    if (value.length < 8) {
      errors.push('Das Passwort muss mindestens 8 Zeichen lang sein.');
    }

    if (errors.length > 0) {
      return Promise.reject(new Error(errors.join(' ')));
    }

    return Promise.resolve();
  }

  return (
    <>
      <Typography.Title level={3}>Benutzer verwalten</Typography.Title>
      <UserSelection users={users} selectedUsers={selectedUsers} handleChangeSelectedUsers={handleChangeSelectedUsers} />
      <br />
      <br />
      {userWithRoles && <>
        <List
          header={<Typography.Title level={3} style={{ margin: 0 }}>Benutzer-Kunde-Rollen ({userWithRoles?.email})</Typography.Title>}
          bordered
          style={{ width: '100%' }}
          key={`user-client-memberships-${userWithRoles.azureObjectId}`}
        >
          {userClientMemberships.map((client) => (
            <List.Item
              key={`uc-membership-${userWithRoles.azureObjectId}-${client.clientId}-${JSON.stringify(client.roles)}`}
            >
              {<Typography style={{ fontWeight: 'bold', width: '300px' }}
              >{client.clientName} ({client.clientId})
              </Typography>}
              <Form
                layout='inline'
                labelWrap={true}
                labelCol={{ span: 40 }}
                wrapperCol={{ offset: 40 }}
                initialValues={client.roles?.reduce((acc, role) => ({ ...acc, [`${client.clientId}-${role}`]: true }), {})}
                onFinish={(values) => updateUserClientRoles(values, client.clientId)}
                key={`form-${userWithRoles.azureObjectId}-${client.clientId}`}
              >
                {userWithRoles?.availableClientRoles?.map((role) => (
                  <Form.Item
                    label={role}
                    name={`${client.clientId}-${role}`}
                    valuePropName="checked"
                    key={`role-${client.clientId}-${role}`}
                  >
                    <Checkbox />
                  </Form.Item>
                ))
                }
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{}}
                  >
                    Speichern
                  </Button>
                </Form.Item>
              </Form>
            </List.Item>
          ))}
          <List.Item
            key={`user-details-add-for-all-${userWithRoles.azureObjectId}`}
          >
            {<Typography style={{ fontWeight: 'bold', width: '300px' }}
            >Rollen für alle Clients hinzufügen
            </Typography>}
            <Form
              layout='inline'
              labelWrap={true}
              labelCol={{ span: 40 }}
              wrapperCol={{ offset: 40 }}
              onFinish={(values) => addUserClientRolesForAllClients(values)}
              key={`add-for-all-${userWithRoles.azureObjectId}`}
            >
              {userWithRoles?.availableClientRoles?.map((role) => (
                <Form.Item
                  label={role}
                  name={`add-all-${role}`}
                  valuePropName="checked"
                  key={`add-role-for-all-${role}`}
                >
                  <Checkbox />
                </Form.Item>
              ))
              }
              <Form.Item>
                <Tooltip title="Fügt die aktiv gesetzten Rechte für alle Clients hinzu. Um diese zu entfernen müssen diese pro client entfernt werden oder für alle Kunden enternt werden.">
                  <Button
                    danger
                    htmlType="submit"
                  >
                    Auswahl hinzufügen
                  </Button>
                </Tooltip>
              </Form.Item>
            </Form>
          </List.Item>
          <List.Item
            key={`user-details-revoke-for-all-${userWithRoles.azureObjectId}`}
          >
            {<Typography style={{ fontWeight: 'bold', width: '300px' }}
            >Rollen für alle Clients entfernen
            </Typography>}
            <Form
              layout='inline'
              labelWrap={true}
              labelCol={{ span: 40 }}
              wrapperCol={{ offset: 40 }}
              onFinish={(values) => revokeUserClientRolesForAllClients(values)}
              key={`revoke-for-all-${userWithRoles.azureObjectId}`}
            >
              {userWithRoles?.availableClientRoles?.map((role) => (
                <Form.Item
                  label={role}
                  name={`revoke-all-${role}`}
                  valuePropName="checked"
                  key={`revoke-role-for-all${role}`}
                >
                  <Checkbox />
                </Form.Item>
              ))
              }
              <Form.Item>
                <Tooltip title="Entfernt die inaktiv gesetzten Rechte für alle Clients hinzu. Um diese zu hinzuzufügen müssen diese pro client hinzugefüt werden oder für alle Clients entfernt werden.">
                  <Button
                    danger
                    htmlType="submit"
                  >
                    Auswahl entfernen
                  </Button>
                </Tooltip>
              </Form.Item>
            </Form>
          </List.Item>
        </List>
        <br />
        <List
          header={<Typography.Title level={3} style={{ margin: 0 }}>Benutzerdetails ({userWithRoles?.email})</Typography.Title>}
          bordered
          style={{ width: '100%' }}
          key={`user-details-${userWithRoles.azureObjectId}`}
        >
          <List.Item
            key={`admin-roles-${userWithRoles.azureObjectId}`}
          >
            {<Typography style={{ fontWeight: 'bold', width: '300px' }}
            >Benutzerdetails anpassen
            </Typography>}
            <Form
              layout='inline'
              labelWrap={true}
              labelCol={{ span: 40 }}
              wrapperCol={{ offset: 40 }}
              initialValues={userWithRoles.adminRoles?.reduce((acc, role) => ({ ...acc, [role]: true }), { email: userWithRoles.email, blockSignin: userWithRoles.blockSignin })}
              onFinish={(values) => updateUser(values)}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Bitte E-Mail eintragen!' }]}
                style={{ width: '300px' }}
              >
                <Input
                  placeholder="Email"
                />
              </Form.Item>
              {userWithRoles?.availableAdminRoles?.map((role) => (
                <Form.Item
                  label={role}
                  name={role}
                  valuePropName="checked"
                  key={`revoke-role-${role}`}
                >
                  <Checkbox />
                </Form.Item>
              ))
              }
              <Form.Item
                label="Account gesperrt"
                name="blockSignin"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Form.Item>
                <Button
                  danger
                  onClick={() => showDeleteConfirm(userWithRoles.azureObjectId)}
                  style={{ marginLeft: '10px' }}
                >
                  Benutzer löschen
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType="submit"
                >
                  Bestätigen
                </Button>
              </Form.Item>
            </Form>
          </List.Item>
        </List>
      </>}
      {selectedUsers.length > 1 &&
        <BulkUserAdministration
          selectedUsers={selectedUsers}
          handleChangeSelectedUsers={handleChangeSelectedUsers}
          users={users}
          setUsers={setUsers}
          adminRoles={adminRoles}
          adminToken={adminToken}
        />}
      <br />
      <Typography.Title level={3}>Benutzer hinzufügen</Typography.Title>
      <Form
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 6 }}
        autoComplete="off"
        onFinish={(values) => createUser(values)}
        initialValues={{ password: generateRandomPassword(), blockSignin: false, data_admin: false, benutzerverwaltung: false, kundenverwaltung: false }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Bitte E-Mail eintragen!' }]}
        >
          <Input
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          label="Vorname Nachname"
          name="name"
          rules={[{ required: true, message: 'Bitte Vorname und Nachname eintragen!' }]}
        >
          <Input
            placeholder="Vorname Nachname"
          />
        </Form.Item>
        <Form.Item
          label="Initiales Passwort"
          name="password"
          rules={[
            { required: true, message: 'Bitte Passwort eintragen!' },
            { validator: validatePassword }
          ]}
        >
          <Input.Password
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          label="Account gesperrt"
          name="blockSignin"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="data_admin"
          name="data_admin"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="benutzerverwaltung"
          name="benutzerverwaltung"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="kundenverwaltung"
          name="kundenverwaltung"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          wrapperCol={{ offset: 6, span: 6 }}
        >
          <Tooltip title="Ungespeicherte Änderungen der derzeit ausgewählten Benutzer werden hiermit verworfen.">
            <Button type="primary" htmlType="submit">
              Benutzer hinzufügen
            </Button>
          </Tooltip>
        </Form.Item>
      </Form>
    </>
  );
};

export default Administration;
